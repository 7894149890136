import { computed, ref, watchEffect } from "vue"
import { useRoute } from "vue-router"
import { useStore } from "vuex"
import { useUpdateCustomerData } from '@/store/composable/Customer'
import { useUser, useRoleBasedConditions } from '@/store/composable/User'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { setBreadCrumbs, setFromBreadCrumbs } from '@/store/composable/BreadCrumbs'

const { role_id } = useUser()
export async function useListing(filters, route_name) {
    const roles = useRoleBasedConditions()

    if(route_name && !roles[conditions(route_name)]) window.location.href = '/#/404'
    
        const params = {
            linkType: route_name,
            ...filters
        }
        let breadcrumb = setBreadCrumbs(params.linkType)
        let fromBreadcrumb = setFromBreadCrumbs(params.linkType)
        setCurrentPageBreadcrumbs(breadcrumb, fromBreadcrumb)
        const response = await useUpdateCustomerData(params)
        return response
}

export function resetLeadsTaskP() {
    dateRanges.value = []
    search.value = ''
    leadsTaskP.value = {
        per_page: 50,
        task_due_dates: "all",
        customer_status_id: -1,
        agent: [1, 14].includes(role_id) ? 'all' : -1,
        lead_status_id: -1,
        keyword: "",
        start_date: "",
        end_date: "",
        insurance_type: -1,
        page: 1
    }
}


export function setPolicyP(route_name) {
    dateRanges.value = []
    search.value = ''
    policyACP.value = {
        agent: [1, 9].includes(role_id) ? 'all' : 'mine',
        end_date: "",
        index: 1,
        keyword: "",
        payment_type: "",
        per_page: 50,
        policy_sales_agent: "",
        start_date: "",
        status: setStatus(route_name),
        task_due_dates: "all",
        type: setType(route_name),
        underwriter: "",
        page: 1
    }
}

export const leadsTaskP = ref({
    per_page: 50,
    task_due_dates: "all",
    customer_status_id: -1,
    agent: [1, 14].includes(role_id) ? 'all' : -1,
    lead_status_id: -1,
    keyword: "",
    start_date: "",
    end_date: "",
    insurance_type: -1,
    page: 1
})

export const policyACP = ref({
    agent: [1, 9].includes(role_id) ? 'all' : 'mine',
    end_date: "",
    index: 1,
    keyword: "",
    payment_type: "",
    per_page: 50,
    policy_sales_agent: "",
    start_date: "",
    status: 2,
    task_due_dates: "",
    type: '',
    underwriter: "",
    page: 1
})

export const elDashboardParams = ref({
    start_date: "",
    end_date: "",
    insurance_type: "",
    per_page: 5,
    page: 1,
})

export const elDashboardParamsNL = ref({
    start_date: "",
    end_date: "",
    insurance_type: "",
    per_page: 5,
    page: 1,
    el_type: 'NL',
})

export const elDashboardParamsPL = ref({
    start_date: "",
    end_date: "",
    insurance_type: "",
    per_page: 5,
    page: 1,
    el_type: 'PL',
})

export const elDashboardParamsLL = ref({
    start_date: "",
    end_date: "",
    insurance_type: "",
    per_page: 5,
    page: 1,
    el_type: 'LL',
})

export const elDashboardParamsEL = ref({
    start_date: "",
    end_date: "",
    insurance_type: "",
    per_page: 5,
    page: 1,
    el_type: 'EL',
})

export const elDashboardParamsCOD = ref({
    start_date: "",
    end_date: "",
    insurance_type: "",
    per_page: 5,
    page: 1,
    el_type: 'COD',
})

export const elDashboardParamsONLINE = ref({
    start_date: "",
    end_date: "",
    insurance_type: "",
    per_page: 5,
    page: 1,
    el_type: 'ONLINE',
})

export const expiredLeadsP = ref({
    per_page: 50,
    page: 1,
    start_date: "",
    end_date: "",
    export: false
})

export function resetExpiredLeadsP() {
    dateRanges.value = []
    expiredLeadsP.value = {
        per_page: 50,
        page: 1,
        start_date: "",
        end_date: "",
        export: false
    }
}

export const leadBySourceP = ref({
    per_page: 50,
    page: 1,
    start_date: "",
    end_date: "",
    lead_status_id: -1,
    source: null,
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_content: null,
    export: false
})

export function resetLeadBySourceP() {
    dateRanges.value = []
    leadBySourceP.value = {
        per_page: 50,
        page: 1,
        start_date: "",
        end_date: "",
        lead_status_id: -1,
        source: null,
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
        utm_content: null,
        export: false
    }
}

export const dashboardDetailsP = ref({
    per_page: 50,
    page: 1,
    start_date: "",
    end_date: "",
    source: null,
    leads: "all",
    sales: "all",
    year: "",
    agent: "",
    export: false
})

export function resetDashboardDetailsP() {
    dateRanges.value = []
    dashboardDetailsP.value = {
        per_page: 50,
        page: 1,
        start_date: "",
        end_date: "",
        source: null,
        leads: "all",
        sales: "all",
        year: "",
        agent: "",
        export: false
    }
}

export const accountMasterP = ref({
    per_page: 50,
    page: 1,
    start_date: "",
    end_date: "",
    keyword: "",
    status: [],
    payment_type: [],
    export: false
})

export function resetAccountMasterP() {
    dateRanges.value = []
    accountMasterP.value = {
        per_page: 50,
        page: 1,
        start_date: "",
        end_date: "",
        keyword: "",
        status: [],
        payment_type: [],
        export: false
    }
}

export const adminReportP = ref({
    year: "",
    insurance_type: 3,
    export: false
})

export function resetAdminReportP() {
    dateRanges.value = []
    adminReportP.value = {
        year: "",
        insurance_type: 3,
        export: false
    }
}

export const customerWithoutLeadP = ref({
    per_page: 50,
    page: 1,
    start_date: "",
    end_date: "",
    export: false
})

export function resetCustomerWithoutLeadP() {
    dateRanges.value = []
    customerWithoutLeadP.value = {
        per_page: 50,
        page: 1,
        start_date: "",
        end_date: "",
        export: false
    }
}

export const productionReportP = ref({
    start_date: "",
    end_date: "",
    insurance_type: ''
})

export function resetProductionReportP() {
    dateRanges.value = []
    productionReportP.value = {
        start_date: "",
        end_date: "",
        insurance_type: ''
    }
}

export const resetSelectedCustomers = (()=>{
    selectAllCustomers.value = false
    transferedCustomers.value = [];
})

export const selectAllCustomers = ref(false)
export const showLeads = ref(false)
export const ltCarLeadCounts = ref([])
export const ltCarLeadToolbarCounts = ref([])
export const dateRanges = ref([])
export const search = ref('')
export const transferedCustomers = ref([])
export const enableLeads = computed(() => {
    if(leadsTaskP.value.agent === -1 || [1, 9, 13, 14].includes(role_id))  return true

    return false
})

export const marketingSpendP = ref({
    campaign: "",
    year_month: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0'),
    year: new Date().getFullYear(),
    insurance_type:'Travel'
})

function conditions(route_name) {
    switch(route_name) {
        case 'customer-list': return 'pLeadsAndTasks'
        case 'policy-assigned': return 'pAssignedPolicy'
        case 'policy-completed': return 'pCompletedPolicy'
        case 'policy-cancellation-request': return 'pCancellationRequest'
        case 'policy-cancelled': return 'pCancelled'
        case 'policy-refund-closed': return 'pRefundClosed'
        case 'policy-cancellation-approval': return 'pApproveViewCancellation'
        case 'policy-refund-approval': return 'pApproveViewRefund'
        case 'expired-leads': return 'pExpiredLeads'
        case 'lead-by-source': return 'pLeadBySource'
        case 'dashboard-details': return 'pDashboardDetails'
        case 'account-master': return 'pAccountMaster'
        case 'admin-report': return 'pAdminReport'
        case 'customers-without-lead': return 'pCusomersWithoutLead'
        case 'production-reports': return 'pProductionReports'
        case 'marketing-campaigns': return 'pMarketingCampaigns'
        case 'marketing-spend':
        case 'marketing-spend-tracking': return 'pMarketingSpend'
        case 'marketing-report':
        case 'marketing-report-agent':return 'pMarketingReport'
    
        default: return ''
    }
}

function setType(route_name) {
    switch(route_name) {
        case 'policy-assigned': return 'policy_assigned'
        case 'policy-completed': return 'policy_completed'
        case 'policy-cancellation-approval':
        case 'policy-cancellation-request': return 'cancellation_request'
        case 'policy-cancelled': return 'cancellation_completed'
        case 'policy-refund-closed': return 'refund_completed'
        case 'policy-refund-approval': return 'refund_approve'
        default: return ''
    }
}

function setStatus(route_name) {
    switch(route_name) {
        case 'policy-assigned': return 2
        case 'policy-completed': return 4
        case 'policy-cancellation-request': return 5
        case 'policy-cancellation-approval': return 6
        case 'policy-refund-closed': return 10
        case 'policy-cancelled': return [7, 11]
        default: return ''
    }
}
