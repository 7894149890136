import User from "@/core/services/car/NEW/User"
const user = JSON.parse(window.localStorage.getItem('user'))

export async function useGetSalesAgents(payload) {

    payload.isTransfer = payload.agent == user.id ? false : true
    delete payload.agent
    
    return await User.getDomSalesAgents(payload)
}

export function useUser() {
    if(!user) return {}
    const role_id = user.role_id
    const user_id = user.id
    const email = user.email
    const policy_agent_id = user.policy_agent_id

    return {
        user,
        role_id,
        user_id,
        email,
        policy_agent_id
    }
}

export function useRoleBasedConditions() {
    if(!user) return defaultPagesValue()
    const role_id = user.role_id

    if(!role_id) return defaultPagesValue()

    const customerBack = ![7, 11].includes(role_id)
    const salesAgentRequired = ![1, 7, 11].includes(role_id)
    const AgentDefaultRole = [2, 9, 13].includes(role_id)
    const showDeleteAction = [1, 9, 13, 4].includes(role_id)
    const showDeleteSalesAgentAction = [2].includes(role_id)
    const showLogAction = [1, 6].includes(role_id)
    const policyLogTab = [1, 9, 14].includes(role_id)
    const showRequestQuote = [1, 2, 9, 13, 14].includes(role_id)
    const setRequestQuote = [1, 14].includes(role_id)
    const showView = [4, 1, 14].includes(role_id)
    const switchApp = [1, 9, 6, 11].includes(role_id)
    const adminManager = [1, 9,14].includes(role_id)
    // const switchApp = false;

    // pages
    const pLeadsAndTasks = [1, 9, 13, 2,14].includes(role_id)
    const pUploadPolicy = [1, 9, 13, 2, 4, 6, 14].includes(role_id)
    const pCUstomerDetails = [1, 9, 13, 2, 6, 7, 11, 14].includes(role_id)
    const pAmendInvoice = [1, 9, 13, 2].includes(role_id)
    const pAssignedPolicy = [1, 9, 13, 2, 4, 6, 14].includes(role_id)
    const pCompletedPolicy = [1, 9, 13, 2, 4, 6, 14].includes(role_id)
    const pExpiredLeads = [1, 6, 9, 11, 14].includes(role_id) // 96 Report Expired Leads
    const pLeadBySource = [1, 9, 11, 14].includes(role_id) // 97 Report Lead By Source
    const pDashboardDetails = [1, 6, 9, 11].includes(role_id) // 98 Report Dashboard Details
    const pAccountMaster = [1, 6, 14].includes(role_id) // 99 Report Account Master
    const pAdminReport = [1, 6, 9].includes(role_id) // 100 Report Admin Report
    const pCusomersWithoutLead = [1, 6, 9, 11, 14].includes(role_id) // 101 Report Customers Without Lead
    const pProductionReports = [1, 6, 14].includes(role_id) // Production reports
    const pMarketingReport = [1, 11, 14, 15].includes(role_id)
    const pMarketingSpend = [1, 11, 14].includes(role_id)
    const pMarketingCampaigns = [1, 11].includes(role_id)


    // leads and tasks
    // firstlayer
    const ltAgentDropDown = [1, 13, 9, 14].includes(role_id)
    const pFirstAgentUnderwriter = [1, 6,9].includes(role_id)

    // qca cannot initae quote
    const allowInitiateQuotes = (![14,6,4].includes(role_id));

    // Dashboards
    const dAgentDashboard = [2, 9, 13].includes(role_id)
    const dAdminDashboard = [1, 14].includes(role_id)
    const dMarketingDashboard = [7, 11].includes(role_id)
    const dAccountsDashboard = [6].includes(role_id)
    // Dashboard Filters Roles
    const dashboardFilters = [1, 2, 9, 13, 4, 7, 11, 14, 6].includes(role_id);
    const dFToday = [1, 14].includes(role_id);
    const dFYesterday = [1, 14].includes(role_id);
    const dFThisMonth = [1, 2, 9, 13, 4, 7, 11, 14, 6].includes(role_id);
    const dFLastMonth = [1, 2, 9, 13, 4, 7, 11, 14, 6].includes(role_id);
    const dFPrevMonth = [1, 2, 9, 13, 4, 7, 11, 14, 6].includes(role_id);
    const dFDateRange = [2, 9, 13].includes(role_id);
    const dFTopRightAgents = [2, 9, 13].includes(role_id);
    const dFYear = [1, 14].includes(role_id);

    const donotShowActionsPolicyAssigned = [2, 6, 13].includes(role_id);

    return {
        customerBack,
        salesAgentRequired,
        AgentDefaultRole,
        showDeleteAction,
        showDeleteSalesAgentAction,
        showLogAction,
        policyLogTab,
        showRequestQuote,
        setRequestQuote,
        showView,
        pUploadPolicy,
        pLeadsAndTasks,
        ltAgentDropDown,
        pCUstomerDetails,
        pAmendInvoice,
        pAssignedPolicy,
        pCompletedPolicy,
        pFirstAgentUnderwriter,
        switchApp,
        adminManager,
        allowInitiateQuotes,
        dAgentDashboard,
        dAdminDashboard,
        dMarketingDashboard,
        dAccountsDashboard,
        dashboardFilters,
        dFToday,
        dFYesterday,
        dFThisMonth,
        dFLastMonth,
        dFPrevMonth,
        dFDateRange,
        dFTopRightAgents,
        dFYear,
        pExpiredLeads,
        pLeadBySource,
        pDashboardDetails,
        pAccountMaster,
        pAdminReport,
        pCusomersWithoutLead,
        donotShowActionsPolicyAssigned,
        pProductionReports,
        pMarketingReport,
        pMarketingSpend,
        pMarketingCampaigns
    }

}

function defaultPagesValue() {
    return {
        pLeadsAndTasks :  true ,
        pUploadPolicy :  true,
        pCUstomerDetails :  true,
        pAmendInvoice :  true,
        pAssignedPolicy :  true,
        pCompletedPolicy :  true,
        pCancellationRequest :  true,
        pCancelled :  true,
        pRefundClosed :  true,
        pApproveViewCancellation : true,
        pApproveViewRefund :  true,
        pApprovedInvoice :  true,
        pDeclinedInvoice :  true,
        pExpiredLeads :  true,
        pLeadBySource :  true,
        pDashboardDetails :  true,
        pAccountMaster :  true,
        pAdminReport :  true,
        pCusomersWithoutLead :  true,
        pProductionReports:true,
        pMarketingCampaigns :  true,
        pMarketingSpend :  true,
        pMarketingReport :  true
    }
}
/**
 *  1	Admin
    2	Sales Agent
    3	Policy User
    4	Underwriter
    5	Document Chaser
    6	Accountant
    7	Marketing Agent
    8	Offline Sales Agent
    9	Sales Manager
    10	Online Marketing
    11	Marketing Admin
    12	Sales Admin
    13	Team Leader
    14 QCA
 */