import { createApp } from "vue";
import App from "./App.vue";
import MainSocket from "./main-socket.js"
import Browser from "./browser.js"
/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
// import i18n from "@/core/plugins/i18n";

//imports for app initialization
// import MockAdapter from "@/core/mock/MockService";
import ApiService from "@/core/services/ApiService";
import CustomerService from "@/core/services/car/CustomerService";
import QuotesService from "@/core/services/car/QuotesService";
import InvoiceService from "@/core/services/car/InvoiceService";
import TaskService from "@/core/services/car/TaskService";
import NotesService from "@/core/services/car/NotesService";
import LeadService from "@/core/services/car/LeadService";
import PolicyService from "@/core/services/car/PolicyService";
import MasterService from "@/core/services/car/MasterService";
import EmailService from "@/core/services/car/EmailService";
import NotificationService from "@/core/services/car/NotificationService";
import ReportService from "@/core/services/car/ReportService";
import AchievementService from "@/core/services/AchievementService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import InfiniteScroll from "infinite-loading-vue3";
// Import the tooltip component and option types
import VueCustomTooltip, { TooltipOptions } from '@adamdehaven/vue-custom-tooltip';
import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";
import Popper from "vue3-popper";
import Editor from "@tinymce/tinymce-vue";
import Overlay from 'vuejs-overlay';
import { initGlobalFunction } from "./core/plugins/global";
import { initSkyeComponent } from '@/core/plugins/components'
import { Actions } from "@/store/enums/StoreEnums";


import Echo from 'laravel-echo';
import Pusher from 'pusher-js';


const app = createApp(App);


if (process.env.NODE_ENV === "production") {
  console.log = () => {
    //
  };
  console.debug = () => {
    //
  };
  console.info = () => {
    //
  };
  console.warn = () => {
    //
  };
}

app.use(store);
app.use(router);
app.use(ElementPlus, []);
app.component("Popper", Popper);
app.component("Editor", Editor);
app.component("Overlay", Overlay);
app.component("InfiniteScroll", InfiniteScroll);
app.use(VueCustomTooltip, {
  name: 'VueCustomTooltip',
  background: '#fff',
  color: '#000',
  borderRadius: 12,
  fontWeight: 400,
});

app.use(MainSocket);
app.use(Browser);
ApiService.init(app);
CustomerService.init(app);
QuotesService.init(app);
InvoiceService.init(app);
TaskService.init(app);
NotesService.init(app);
PolicyService.init(app);
LeadService.init(app);
MasterService.init(app);
EmailService.init(app);
NotificationService.init(app);
ReportService.init(app);
AchievementService.init(app);
// MockAdapter.init(app);
//initApexCharts(app);
initInlineSvg(app);
initGlobalFunction(app);
initSkyeComponent(app);
initVeeValidate();

// app.use(i18n);

// store.dispatch(Actions.VERIFY_AUTH)
//   .then(() => {
//     const user = store.getters.currentUser;
//     if (!user) {
//       store.dispatch("logout");
//     }
    router.isReady()
      .then(() => {
        app.mount("#app");
      })
  // }).catch(() => {
  //   localStorage.clear();
  //   router.isReady()
  //     .then(() => {
  //       app.mount("#app");
  //     })
  // })
