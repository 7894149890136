import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'reverb',
    key: process.env.VUE_APP_REVERB_KEY,
    wsHost: process.env.VUE_APP_REVERB_HOST,
    wsPort:process.env.VUE_APP_REVERB_PORT,
    cluster: process.env.VUE_APP_REVERB_CLUSTER,
    // wssPort: process.env.VUE_APP_REVERB_PORT,
    forceTLS: false,
    enabledTransports: ['ws']// wss

});


export default window.Echo;
